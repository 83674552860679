@import "./consts.scss";

.bar-chart-vertical {
    .recharts-wrapper {
        direction: ltr;
    }

    .recharts-legend-item {
        margin-left: 2vw;
        direction: rtl;
    }
    .legend-label {
        color: $texts;
        margin-right: 6%;
        font-size: vh(2);
    }
}

.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $dark-blue;
    font-size: vh(3.5);
    font-weight: 600;
}
