@import "./consts.scss";

.average-time-post {
    text-align: center;

    .days {
        color: $dark-blue;
        font-size: vh(6);
        font-weight: 700;

        span {
            font-size: vh(3);
            font-weight: 500;
            margin-right: 7%;
        }
    }

    .description {
        color: $texts;
        font-size: vh(2);
        font-weight: 500;
        margin-top: vh(2.8);
    }

    &:hover {
        .days {
            color: #4dd194;
        }
    }
}
