@import "./consts.scss";

.not-relevant-event {
    background-color: $header;
    box-shadow: 0px 3px 6px $box-shadow-transparent;
    width: 100%;
    text-align: right;
    font-size: vh(2.2);
    color: $texts;
    padding: vh(2) vh(1.8);
    box-sizing: border-box;

    .title {
        font-weight: bold;
    }

    span.title {
        margin-left: vh(1);
    }

    .organization-details {
        display: flex;
        align-items: center;
        gap: vh(0.5);
        margin-top: vh(2);
        font-weight: 500;

        img {
            width: vh(3.5);
            aspect-ratio: 1;
            border-radius: 50%;
            box-shadow: 0px 3px 6px $box-shadow;
            margin-left: vh(0.5);
        }
    }
}
