@import "./consts.scss";

.clicks-stats-container {
    width: 25vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;

    .clicks-count {
        text-align: right;
        color: $texts;
        font-size: vh(2.3);
        margin-right: 1.5vw;
        margin-left: 1.5vw;
        .number {
            color: $dark-blue;
            font-size: vh(5);
            font-weight: 700;
        }
    }
}
