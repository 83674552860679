@import "./consts.scss";

$blue-background: #1ececb;

.generic-button.MuiButtonBase-root {
    border-radius: 3.698vw;
    border: 0;
    font-family: $font;
    font-size: 1.5vw;
    font-weight: 700;
    min-width: vh(15);
    text-align: center;
    box-shadow: 0px 3px 6px $box-shadow;
    color: $texts;
    outline: none;
    margin: unset;
    width: fit-content;

    &.disabled {
        filter: brightness(70%);
        background-color: $header;
        color: #8b8b8b;
    }

    &:hover {
        cursor: pointer;
        filter: brightness(110%);
    }

    &:focus {
        filter: brightness(80%);
    }

    .MuiTouchRipple-root * {
        color: $header;
    }

    &.darkBlue {
        background-color: $texts;
        color: $header;
    }

    &.yellow {
        background-color: $btn-yellow;
    }

    &.turquoise {
        background-color: $btn-turquoise;
    }

    &.big {
        margin-top: vh(1.5);
        width: 28.5vw;
        height: vh(5);
        color: white;
        font-size: 1.3vw;
    }

    &.small {
        width: 13.125vw;
        height: vh(7);
        font-size: 1.823vw;
    }

    .loading-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
