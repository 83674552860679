@import "./consts.scss";

.agencies-canceled-events-container {
    width: 100%;

    .title {
        text-align: right;
        color: $texts;
        font-size: vh(2.6);
        font-weight: 700;
        margin-bottom: vh(2);
    }

    .text-number-row {
        margin-bottom: vh(1);
    }
}
