button.floatingButton:global(.MuiButtonBase-root) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  left: 0;
  bottom: 75px;
  padding: 13px 26px;
}

button.labelWithOffset:global(.MuiButtonBase-root) {
  padding-left: 60px;
}
