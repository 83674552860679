@import "./consts.scss";

.details-ticket-event-live {
    background-color: $background-color-primary;
    border-color: black;
    border-radius: vh(3.148);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 95vw;
    height: vh(90);
    display: flex;
    flex-direction: column;
    color: $texts;

    .header-ticket-event-popup {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;

        .close-button-text-popup-details-tickets {
            font-size: vh(6);
        }
    }
    .title-ticket-event-popup {
        margin: auto;
    }
    .title {
        text-align: center;
        font-size: vh(3);
    }

    .sub-title-ticket-event-popup {
        display: flex;
        align-items: center;
        font-size: vh(2.5);

        .sub-title-divider {
            margin: 0 vh(2);
        }
        .agency-name,
        .artist-name {
            font-weight: bold;
            margin: 0 vh(1);
        }
        p {
            direction: rtl;
        }
    }

    .table {
        background-color: transparent;
        width: 80%;
        margin: 0 auto;
        border-spacing: 0 10px;
    }

    .table-head-row {
        background: transparent;
        font-size: vh(2.4);
        font-weight: bold;
        color: $texts;
    }

    .table-head-row:first-child {
        text-align: right;
    }

    .table-body-row {
        background-color: $header;
        margin-bottom: 20px;

        .table-body-cell-name-img {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 vh(0.5);

            .img-box {
                width: 6vh;
                height: 6vh;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                margin-left: vh(1);
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .table-body-row:last-child {
        text-align: right;
    }

    .table-body-cell-input {
        border: none;
        border-bottom: 1px solid $texts;
        opacity: 0.7;
        width: vh(10);
        margin-right: vh(3);
        direction: rtl;
    }

    .table-body-cell-input:focus-visible {
        outline: none;
        border-bottom: 1px solid $texts;
        width: vh(10);
        margin-right: vh(3);
    }

    .save-btn-popup-tickets-details {
        width: 80%;
        margin: vh(3) auto;
    }
    .btn-save {
        width: vh(5);
        height: vh(5);
        font-size: vh(2.4);
    }
}
