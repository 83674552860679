@import "./consts.scss";

.not-relevant-events-popup {
    direction: rtl;
    width: 90vw;
    height: 90vh;

    .the-title {
        margin-top: vh(-5);
        font-size: vh(3);
    }

    .events-container {
        width: 80%;
        overflow-y: scroll;
        max-height: vh(80);
        direction: ltr;

        & > div {
            direction: rtl;
            margin-bottom: vh(1.5);
        }

        &::-webkit-scrollbar {
            width: 0.2vw;
        }
        &::-webkit-scrollbar-track {
            background-color: #e8e8ee13;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $scroll-color-dark;
            border-radius: vh(10);
        }
    }
}
