@import "./consts.scss";

.event-num-container {
    margin-bottom: auto;
    text-align: center;

    .num-of-events {
        color: $dark-blue;
        font-size: calc(var(--vh, 1vh) * 7);
        font-weight: 700;
    }

    .event-num-description {
        color: $texts;
        font-size: vh(2.5);
        font-weight: 500;
    }
}
