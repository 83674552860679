@import "./consts";

.yellow-banner-wrapper {
    z-index: 3;
    position: absolute;
    left: 5%;
    top: -2%;
    color: $texts;
    .yellow-banner-container {
        box-sizing: border-box;
        width: vh(9);
        height: vh(9);
        border: 0;
        border-radius: vh(0.5);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        background-color: $btn-yellow;
        z-index: 3;
        .yellow-banner-text {
            position: relative;
            width: 100%;
            height: 100%;
            font-size: vh(1.6);
            display: flex;
            text-align: center;
            align-items: flex-end;
            padding-bottom: 5%;
            font-weight: 600;
            line-height: 80%;
            box-sizing: border-box;
        }
        .yellow-banner-number {
            width: 100%;
            text-align: center;
            position: absolute;
            top: vh(-1);
            font-size: vh(6);
            font-weight: 800;
        }
        .yellow-banner-number-small {
            width: 100%;
            text-align: center;
            position: absolute;
            top: vh(-1);
            font-size: vh(5);
            font-weight: 800;
        }
    }
}
.yellow-banner-container:before,
.yellow-banner-container:after {
    content: "";
    position: absolute;
    background-color: inherit;
}
.yellow-banner-container,
.yellow-banner-container:before,
.yellow-banner-container:after {
    width: 60.5%;
    height: 50%;
    border-top-right-radius: 30%;
}

.yellow-banner-container:after {
    right: 0%;
    z-index: -1;
    margin-top: vh(-2.7);
    transform: rotate(180deg) skewY(35deg) scaleX(-1);
}
.yellow-banner-container:before {
    position: absolute;
    left: 0%;
    z-index: -1;
    margin-top: vh(6.3);
    left: 0;
    transform: skewY(-35deg) rotate(180deg);
}

.yellow-banner-wrapper.isEvent {
    top: unset;

    .yellow-banner-container {
        width: vh(13);
        height: vh(15);

        .yellow-banner-text {
            font-size: vh(2.5);
        }
        .yellow-banner-number {
            font-size: vh(10);
        }
    }
    .yellow-banner-container:before {
        margin-top: vh(12);
        height: 50%;
        width: 61%;
    }

    .yellow-banner-container:after {
        height: 50%;
        width: 51%;
    }

    .yellow-banner-container:after {
        right: 0.5%;
    }
}

@media (max-width: $breakpoint) {
    .yellow-banner-wrapper {
        top: 2%;
    }

    .yellow-banner-wrapper.isEvent {
        .yellow-banner-container {
            width: vh(9);
            height: vh(9);

            .yellow-banner-text {
                font-size: vh(1.6);
            }
            .yellow-banner-number {
                font-size: vh(6);
            }
        }

        .yellow-banner-container:before {
            margin-top: vh(6.3);
            width: 54%;
        }

        .yellow-banner-container:after {
            right: 0%;
        }
    }
}
