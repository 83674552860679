@import "./consts.scss";

.value-container {
    text-align: center;

    .num-value {
        color: $dark-blue;
        font-size: vh(7);
        font-weight: 700;
    }

    .value-title {
        color: $texts;
        font-size: vh(3);
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0;

        & > p {
            margin: 0;
        }
    }
}
