@import "./consts.scss";

.shadow-button {
    background-color: $btn-yellow;
    box-shadow: 0px 0.2vw 0.5vw #00000029;
    border-radius: 35px;
    opacity: 1;
    width: 11vw;
    height: vh(4.8);
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .button-content {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }

    .text {
        font: normal normal bold 1.5vw/2vw Assistant;
        color: $dark-blue;
    }

    .download-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: vh(0.2);
        width: 1vw;
        height: vh(4.8);
        margin-left: 0.5vw;

        .arrow {
            width: 80%;
        }

        .line {
            width: 100%;
        }

        &.loading {
            .arrow {
                animation: up-and-down 0.5s infinite alternate;
            }
        }
    }
}

@keyframes up-and-down {
    0% {
        transform: translateY(-15%);
    }

    50% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(10%);
    }
}