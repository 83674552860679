@import "./consts.scss";

.bar-separated-values-container {
    direction: rtl;
    display: flex;

    .value-container {
        flex: 1;
        text-align: center;

        &:first-child {
            border-left: 0.5px solid $separator-bar;
        }

        .num-value {
            color: $dark-blue;
            font-size: vh(7);
            font-weight: 700;
        }
        .value-title {
            color: $texts;
            font-size: vh(3);
            font-weight: 500;
        }
    }
}
