@import "./consts.scss";

.archive-item-box.MuiPaper-root {
    border: 0.1vh solid $background-color-primary;
    background-color: $background-color-primary;
    box-shadow: 5px 6px 6px $box-shadow;
    font-family: $font;
    color: $texts;

    .MuiCardActionArea-root {
        padding: 1vh 1.8vw;
    }

    .beggining {
        width: 26%;
        justify-content: flex-end;
        font-weight: 600;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .artist {
            margin-left: 1vw;
            font-weight: 700;
        }
        .date {
            font-weight: 400;
        }
    }

    .amount {
        display: flex;
        align-items: center;
        font-weight: 700;
        gap: 0.8vw;

        .ticket {
            img {
                width: 1.6vw;
            }
        }
    }

    .arrow {
        display: flex;
        align-items: center;
        width: 10%;
        img {
            width: 1vw;
            margin-left: 5vw;
        }
    }

    .agency-name {
        width: 10%;
        margin-left: 3.5vw;
    }
}
