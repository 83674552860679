@import "./consts.scss";

.container-of-logos {
    max-height: vh(20);
    overflow: hidden auto;
    @include scrollBar-thin;

    .inner-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-bottom: vh(1.85);
        margin-top: vh(1);

        .logo {
            background-color: $header;
            width: 3.2vw;
            height: 3.2vw;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            margin-left: 1vw;
            margin-right: 1vw;

            img {
                width: 3.5vw;
                border-radius: 50%;
                height: 4.2vh;
            }
        }

        .name {
            text-align: center;
            font: normal normal bold 30px/40px Assistant;
            color: $texts;
            margin-left: 1.5vw;
            font-size: vh(2.5);
        }
    }
}
