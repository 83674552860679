@import "./consts.scss";

.text-number-row {
    background-color: $header;
    box-shadow: 0px 3px 6px $box-shadow-transparent;
    width: 100%;
    color: $texts;
    display: flex;
    justify-content: space-between;
    font-size: vh(3);
    font-family: $font;
    padding: vh(2.5) vh(7);
    box-sizing: border-box;
    margin: 0 auto;

    .text {
        font-weight: 500;
    }

    .number {
        color: $dark-blue;
        font-weight: 600;
    }
}
