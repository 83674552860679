@import "./consts.scss";

.bar-chart-container {
    .bar-chart-wrapper {
        direction: ltr;
        margin-left: auto;
        width: 100%;
        height: 100%;
    }

    svg.recharts-surface {
        overflow: visible;

        g.recharts-cartesian-axis-tick {
            text-align: right;
            color: $texts;
            font: 500 2vh Assistant, sans-serif;

            .category-label {
                font-size: 2.2vh;
            }
        }
    }
}
