@import "./consts.scss";

@keyframes animation {
    from {
        height: vh(3);
        top: vh(0);
    }

    to {
        height: vh(38);
        top: vh(-35.1);
    }
}

.other-pages,
.profile-page {
    width: 90vw;
    height: vh(70);
    margin-right: 5vw;
    padding-top: 2vh;
    padding-bottom: 3vh;
    border-radius: 1vw;
    margin-top: 2vh;
    background-color: white;
    padding: 2vh 2vw 3vh;
    box-sizing: border-box;
    overflow: hidden;

    .system-name {
        display: flex;
        align-items: center;
        color: $dark-blue;

        .arrow-img {
            margin-left: 0.7vw;

            img {
                width: vh(3);
            }
        }

        .the-name {
            font-size: 1.8vw;
            font-weight: 700;
        }
    }

    .navbar-profile {
        margin-left: 7vw;
        border-bottom: $border-light-gray 0.3vh solid;
        position: relative;

        .pro-name {
            font: normal normal normal 1.853vh/2.409vh Assistant;
            color: $texts;
            font-weight: 700;
            text-align: right;
            display: flex;
            align-items: center;
            direction: rtl;
            justify-content: space-between;

            .actual-name {
                font-size: 1.2vw;
                margin-top: vh(5);
                margin-bottom: vh(1.7);
                margin-right: 1.5vw;
            }
        }

        .no-little {
            position: absolute;
            top: vh(8);
            width: 6vw;
            height: vh(0.1);
            border-radius: vh(7);
            background-color: $texts;
        }
    }

    .big-pdf-container {
        margin-top: vh(2);

        .pdf-container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            .wrapper-file {
                display: flex;
                flex-direction: column;

                .pdf-box {
                    overflow: hidden;
                    margin-left: 3vw;
                    margin-bottom: vh(0.5);
                    width: 13vw;
                    height: vh(35);
                    position: relative;
                    border: vh(0.17) solid #cfcfcf;
                    border-radius: 0.3vw;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

                    .pdf-item {
                        .error-file {
                            text-align: center;
                            margin-top: vh(15);
                            font-size: 2.2vh;
                            font-weight: 600;
                        }

                        img {
                            background-color: $texts;
                            display: none;
                        }

                        position: relative;
                        width: 100%;
                        height: 100%;

                        border-radius: 3px;
                        opacity: 1;

                        .react-pdf__Page {
                            .react-pdf__Page__canvas {
                                position: relative;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                width: 13vw !important;
                                height: vh(35) !important;
                            }
                        }
                    }

                    .pdf-name {
                        width: -webkit-fill-available;
                        border-radius: 0px 0px 3px 3px;
                        opacity: 1;
                        height: vh(3);
                        line-height: vh(3);
                        background-color: #f5f5f5;
                        position: absolute;
                        height: vh(3);
                        bottom: 0;
                        transition: 0.5s ease;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        .name-pdf-img {
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            gap: 2%;
                            font-size: vh(1.5);

                            img {
                                width: 0.7vw;
                            }
                        }

                        .download-file {
                            display: none;
                            width: 5vw;
                            margin-top: vh(3);
                            background-color: $texts;
                            border: 0;
                            opacity: 0;

                            img {
                                width: vh(1.5);
                            }
                        }

                        .download-hover {
                            opacity: 0;
                            position: absolute;
                            top: 63%;
                            transition: 0.2s ease;
                            font-size: vh(1.5);
                        }
                    }

                    &:hover {
                        .pdf-name {
                            height: 100%;

                            .name-pdf-img {
                                align-self: center;
                                font-size: vh(1.7);
                            }

                            .download-file {
                                display: block;
                                opacity: 1;
                                transition-delay: 1s;
                                width: 7.5vw;
                                border-radius: 0.3vw;
                                height: vh(3.2);
                                transition-delay: unset;

                                &:hover {
                                    background-color: #24323d;

                                    & + .download-hover {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .pdf-date {
                text-align: right;
                display: flex;

                .pdf-validation {
                    color: $admin-profile-tag;
                    font: normal normal Assistant;
                    font-weight: 700;
                    margin-left: vh(1);
                }

                .pdf-val-date {
                    font: normal normal Assistant;
                    color: $texts;
                }
            }
        }
    }
}
