@import "./consts.scss";

.mini-nav-full {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $border-light-gray 0.3vh solid;

    &.main-nav {
        margin-right: 5vw;
    }

    .MuiTabs-root {
        min-height: unset;

        .MuiButtonBase-root.MuiTab-root {
            font: 2.2vh/2.409vh Assistant;
            color: $texts;
            flex-direction: row-reverse;
            padding: 1vh 1.5vh;
            max-width: unset;
            min-width: unset;
            min-height: unset;

            &.Mui-selected {
                font-weight: 700;
            }
        }

        .MuiTabs-indicator {
            background-color: $texts;
            height: vh(0.3);
            border-radius: 5px;
        }
    }

    .notification-circle {
        border-radius: 50%;
        background-color: $texts;
        width: 1vw;
        height: 1vw;
        margin-right: 0.3vw;
        margin-top: 6px;
        color: $header;
        font-weight: 500;
        font-size: 0.8vw;
        line-height: 1vw;
    }

    .two-number {
        font-size: 0.5vw;
    }

    .edit-buttons-container {
        display: flex;
        gap: 1vh;
        height: 3.5vh;

        .edit-profile-btn {
            background-color: $texts;
            color: white;
            padding: 0.5vh 2vh;
            font-size: vh(2);
            min-width: auto;
            box-shadow: none;

            &.cancel {
                background-color: white;
                color: $texts;
                border: 1px solid $texts;
                font-weight: 500;
            }

            .text-on-button {
                display: flex;
                align-items: center;
                gap: 1vh;
                img {
                    height: 1.5vh;
                }
            }
        }
    }
}
