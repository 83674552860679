@import "./consts.scss";

.container-info-flex {
    background-color: rgba(65, 89, 107, 0.04);
    width: auto;

    text-align: right;
    letter-spacing: 0.68px;
    font: normal normal 600 1.302vw/1.719vw Assistant;
    color: $texts;

    display: flex;
    align-items: center;

    .small-container {
        display: flex;
        align-items: center;
        margin-right: 10.833vw;
        max-width: 25vw;
        margin-top: 2vw;
        margin-bottom: 2vw;

        .img-container {
            width: 2.126vw;
            height: auto;
            margin-left: 1.589vw;
            display: flex;
            justify-content: flex-end;

            img {
                max-width: 100%;
            }
        }

        .orgs {
            text-decoration: underline;
        }
    }
}
