.vip-banner {
    position: absolute;
    width: 17vw;
    top: -4px;
    left: -4px;
    rotate: -90deg;
}

.vip-banner-agency {
    rotate: initial;
    right: -4px;
    top: -2.5px;
}
