@import "./consts.scss";

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
}

.input-title {
    font-weight: 600;
    color: $texts;
    font-size: vh(2.3);
    margin-top: vh(1);
    margin-bottom: vh(1);
}

.input {
    background-color: $inputs-background-color;
    border: 0.20000000298023224px solid $inputs-border;
    direction: rtl;
    text-indent: 7px;
    color: $texts;
    border-radius: vh(3);
    font-size: vh(1.8);
    font-family: $font;
    height: vh(4.2);
    padding: 0 0.5vh;
    box-sizing: border-box;
}

.search {
    width: 25vw;
    font-weight: 500;
    padding-top: 2.2vh;
    padding-bottom: 2.2vh;
    border-radius: 0;
    background-color: white;
    font-size: 2.5vh;
}

.category-input {
    outline: none;
    background-color: unset;
    border: unset;
    direction: rtl;
    text-indent: 9px;
    color: $texts;
    font-size: vh(2);
    height: vh(3);
    font-family: $font;
    margin-right: 5vw;
    align-self: self-start;
    width: 65vw;
    font-weight: 500;
}

.input-textarea {
    @extend .input;
    max-width: 100%;
    max-height: vh(14);
    min-height: vh(10);
    min-width: 100%;
}

.input:focus {
    outline: transparent;
}

.input-error {
    color: $error;
    font-size: vh(1.7);
    text-align: right;
    margin-bottom: vh(1);
    height: vh(1);
    font-weight: 400;
    margin-right: 0.5vw;
}

.input.time {
    direction: ltr;
    width: 100%;
}

.password-eye {
    position: relative;

    .eye {
        position: absolute;
        top: 50%;
        left: 5%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;

        img {
            height: 1.5vh;
        }
    }
}

.profile-item-data {
    .input-title,
    .input-error {
        height: 0;
    }
    .input {
        background-color: $inputs-background-color;
        border: vh(0.2) solid $inputs-border;
        border-radius: 0;
        font-size: vh(2.1);

        &:disabled {
            background: none;
            border: none;
            font-weight: 700;
        }
    }
}
