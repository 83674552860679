@import "./consts.scss";

.dropdown-container {
    //the dropdown container that holds the dropdown elements
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    background: $header;
    box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    z-index: 9999; // make sure that the dropdown is always on top
    height: fit-content;
    width: 10.521vw;
    background-color: #b9b9b9;
    border-radius: 18%;
    margin-top: vh(1.5);

    .dropdown-element {
        //each element in the dropdown
        height: fit-content;
        padding-top: vh(0.5);
        padding-bottom: vh(1);
        margin-bottom: 1px;
        flex-grow: 0.322;
        background-color: $header;
        text-align: right;
        font: normal normal normal 1.853vh/2.409vh Assistant;

        .element-text {
            margin-top: 5%;
            margin-left: 2vw;
        }

        &:hover {
            cursor: pointer;
        }
        .log-out-img {
            height: vh(0);
            img {
                width: 1.4vw;
                margin-right: 1.2vw;
            }
        }
    }
    .dropdown-element:last-child {
        border-radius: 0.9vh 0.9vh 0.9vh 0.9vh;
        display: flex;
        justify-content: space-evenly;
        align-items: baseline;
        flex-direction: row-reverse;
    }

    .dropdown-element:first-child {
        .element-text {
            margin-right: 20%;
        }
    }
}
