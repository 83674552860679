@import "./consts.scss";

.statistics-page {
    direction: rtl;
    width: 90vw;
    margin: 0 auto;
    overflow: hidden auto;
    padding: 3vh 5vw;

    .buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 1vw;
        margin-bottom: vh(2);
    }

    .statistics-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1vw;
        margin-bottom: 5vh;
    }
}

.specific {
    height: 58vh;
    width: 85vw;
    padding: unset;

    .white-box-wrapper {
        background-color: $background-color-primary;
    }
}
