@import "./consts.scss";

.dropdown-btn {
    direction: rtl;
    position: relative;
    box-sizing: border-box;
    width: 15vw;
    height: vh(4.8);
    padding: 0 vh(2.5);
    font-size: vh(2.5);
    color: $texts;
    background-color: #bcbccb2b;
    border: 0.2px solid #0c3e89;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .arrow {
        width: vh(1.2);
        rotate: -90deg;

        &.arrow-up {
            rotate: 90deg;
        }
    }

    .dropdown-popup {
        text-align: right;
        z-index: 2;
        position: absolute;
        top: 2.8vw;
        right: 0;
        width: 100%;
        background-color: $header;
        box-shadow: 0px 5px 6px $box-shadow-light;
        border-radius: 5px;

        .option {
            padding: vh(1) vh(2.5);

            &.selected {
                font-weight: 700;
            }
        }
    }
}
