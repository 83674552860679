@import "./consts.scss";

.login-page {
    width: 100vw;
    background-color: $background-color-primary;

    .login-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $texts;
        font-family: $font;
        margin-top: vh(15);

        .login-first-title {
            font-weight: 700;
            font-size: vh(8);
            margin-bottom: vh(1);
        }

        .login-second-title {
            font-weight: 700;
            font-size: vh(4);
            margin-bottom: vh(4);
        }

        .link {
            color: $texts;
            font-size: 1vw;
            font-weight: 300;
            margin-top: vh(3.5);
        }
    }
}
