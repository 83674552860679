@import "./consts.scss";

.live-events-container {
    direction: rtl;
    padding: 0 6.5vw;

    .search-container {
        right: 0;
    }

    .live-events-list {
        height: calc(100vh - #{$header-height} - 20vh);
        overflow-y: auto;
        @include scrollBar-thin;
        padding-right: 2px;

        & > div {
            margin-bottom: vh(4);
        }

        .no-results {
            text-align: center;
            font-size: vh(4);
            color: $texts;
            font-weight: 700;
        }
    }
}
