@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");

$font: "Assistant", sans-serif;
$dark-blue: #0C414F;
$light-blue: #95C1CE;
$texts: #41596b;
$box-shadow: #00000029;
$box-shadow-light: #7d7d7d45;
$box-shadow-transparent: #00000014;
$header: #ffffff;
$background-color-primary: #f6f7f5;
$background-color-left: #eaf4f3;
$header-logo: #057773;
$error: #fd003c;
$btn-yellow: #EDA424;
$btn-blue: #02cecb;
$btn-turquoise: #50d2ce;
$popup-background: #41596b94;
$check-box-radio: #3e3e3e;
$breakpoint: 768px;
$feedback-text: #06837f;
$inputs-background-color: #bcbccb2b;
$inputs-border: #0c3e89;
$scroll-color: #ececf2;
$scroll-color-dark: #b1b1b8;
$separator-bar: #41596b33;
$gray-separator: #80808015;
$border-light-gray: #ececf246;
$dark-white: #e8e9ed;
$transparent-light-gray: #d5d5d53b;
$lighter-gray: #8b8b8b;
$admin-profile-tag: #41596b66;
$tickets-status-blue: #3da6a4;

$event-image-ratio: 3;

$header-height: 11vh;

@function vh($quantity) {
    @return calc(var(--vh, 1vh) * #{$quantity});
}

@mixin scrollBar-thin {
    &::-webkit-scrollbar {
        width: 0.2vw;
    }

    &::-webkit-scrollbar-track {
        width: 0.2vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scroll-color;
    }
}

@mixin popup-scroll {
    &::-webkit-scrollbar {
        width: 0.4vw;
    }
    &::-webkit-scrollbar-track {
        background-color: #d2d2d813;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $scroll-color-dark;
        border-radius: vh(10);
    }
}
