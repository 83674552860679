@import "./consts.scss";

.agency-org-buttons {
   display: flex;
   flex-direction: column;
}

.showOrgAgency {
   font-size: 1.5vw;
}
