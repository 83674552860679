@import "./consts.scss";

.container-content {
    // the whole container of the chat
    margin-top: vh(8.34);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .container-content-right {
        // the right side of the chat
        width: vh(7.23);
        .container-content-right-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: vh(1.85);
        }
    }
    .logo {
        // the white circle in the right side of the speech bubble (Logo,name)
        background-color: $header;
        width: 5.208vw;
        height: 5.208vw;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: vh(0.5);
        img {
            width: 5.3vw;
            border-radius: 50%;
        }
    }
    .name {
        // the text under logo of the sender
        text-align: center;
        font: normal normal bold 1.563vw/2.083vw Assistant;
        color: $texts;
    }
    .single-message {
        // every horizontal row is message
        display: flex;
    }
}
.bubble {
    // the grey box
    margin-right: 2.318vw;
    position: relative;
    background: rgba(65, 89, 107, 0.1) 0% 0% no-repeat padding-box;
    font: normal normal 1.563vw/2.083vw Assistant;
    color: rgba(65, 89, 107, 1);
    text-align: -webkit-auto;
    direction: rtl;

    //size of the bubble
    max-width: 46.875vw;
    width: fit-content;
    height: fit-content;
    padding: vh(4.44);
    img {
        border-radius: 10%;
    }
}
.bubble:after {
    content: "";
    position: absolute;
    display: block;
    z-index: 1;
    border-style: solid;
    border-color: transparent rgba(65, 89, 107, 0.1);
    border-width: 15px 0 15px 15px;
    top: 40%;
    right: -15px;
    margin-top: -19px;
}
.hidden {
    visibility: hidden;
}

.container-content-left {
    margin-right: 0.5vw;
    .bubble {
        font-size: vh(3);
    }
}

.last-img-in-bubble {
    @extend .bubble;
    margin-bottom: vh(6);
}

.last-img-in-bubble:after {
    content: "";
    position: absolute;
    display: block;
    z-index: 1;
    border-style: solid;
    border-color: transparent rgba(65, 89, 107, 0.1);
    border-width: 15px 0 15px 15px;
    top: 40%;
    right: -15px;
    margin-top: -19px;
}
