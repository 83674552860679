@import "./consts.scss";

.event-timeline {
    .timeline-container {
        display: flex;
        justify-content: center;
        gap: vh(1.5);
        margin: vh(4) auto vh(8);
        padding-right: 4vw;
    }

    .details-container {
        display: flex;
        gap: 6.5vw;
        margin-bottom: vh(2);

        & > div {
            width: 9vw;
            height: 19vh;
        }

        hr {
            opacity: 40%;
        }
    }
}
