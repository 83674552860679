@import "./consts.scss";

.canceled-events-description {
    text-align: right;

    .canceled-desc-title {
        color: $texts;
        font-size: vh(2.6);
        font-weight: 700;
        margin-bottom: vh(2);
    }

    .not-relevant-event {
        margin-bottom: vh(1);
    }
}
