@import "./consts.scss";

.bar-chart-tooltip {
    background-color: $background-color-primary;
    box-shadow: 0px 5px 6px $box-shadow-light;
    color: $texts;
    direction: rtl;
    text-align: right;
    padding: vh(0.5) vh(1);

    .label {
        font-size: vh(2.2);
        font-weight: 700;
    }

    .value {
        font-size: vh(1.8);
        font-weight: 500;
    }
}
