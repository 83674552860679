.generic-switch-wrapper {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;

    .switch-label {
        font-size: 2.5vh;
        margin-right: 0.5vw;
        width: 10vw;
        font-weight: 600;
    }
}