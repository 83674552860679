@import "./consts.scss";

.average-time-from-post {
    text-align: center;

    .time,
    .time-specific {
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 4%;
        color: $dark-blue;
        font-size: vh(6);
        font-weight: 700;

        .time-unit {
            .text {
                font-size: vh(2.5);
                font-weight: 500;
                margin-top: vh(-1);
            }
        }
    }

    .days-number {
        color: $dark-blue;
        font-size: vh(7);
        font-weight: 700;
        .days{
            font-size: vh(3);
            font-weight: 500;
        }
    }

    .description {
        color: $texts;
        font-size: vh(2);
        font-weight: 500;
        margin-top: vh(1);
    }

    &:hover {
        .time {
            color: #fdc100;
        }
    }
}