@import "./consts.scss";

.profile-box {
    height: vh(57);
    overflow: hidden auto;
    display: flex;
    @include scrollBar-thin;

    .profile-image {
        color: $dark-white;
        padding: 0 2vw;
        width: calc(5.35vw + 7.5vh);

        .profile-pic-wrapper {
            width: 100%;
            aspect-ratio: 1;
            height: auto;
            margin-top: vh(3);
        }
    }

    .array-map {
        display: flex;
        flex-direction: column;
        width: 55vw;
        margin-top: vh(3);

        .profile-item {
            display: flex;
            margin: 1vh 0;
            padding-right: 2vw;
            line-height: 5vh;
            color: $texts;
            border-bottom: 1px solid #41596b66;
            align-items: center;

            .profile-item-name {
                font-size: 1.1vw;
                margin-right: 0.5vw;
                font-weight: 600;
                width: 16vw;
                color: $admin-profile-tag;
            }

            .profile-item-data {
                flex: 1;
                font-size: 1.1vw;
                font-weight: 600;
            }

            .profile-item-contacts {
                flex: 1;
                display: flex;
                flex-direction: column;

                .profile-item-contact-data {
                    display: flex;
                    justify-content: space-between;
                }

                .profile-item {
                    margin: 0;
                    padding-right: 0;
                    border: none;
                }
            }
        }

        .place {
            background-color: $transparent-light-gray;
            padding: 1vw 2vw;
            margin-top: vh(3);

            .profile-item {
                margin: 0;
                padding-right: 0;
                line-height: 5vh;
                border: none;

                .profile-item-name {
                    flex: 2;
                    min-width: 14vw;
                    color: $texts;
                }

                .profile-item-data {
                    flex: 2;

                    input:disabled {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
