@import "./consts.scss";

.wrapper-pie {
    text-align: -webkit-center;

    .legend-label {
        color: $texts;
    }
}

.wrapper-div {
    -webkit-text-fill-color: black;
}

.custom-tooltip {
    background-color: #f5eeee;
    text-align: right;
    font-size: 2vh;
    padding-right: 1vw;
    padding-left: 1vw;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    outline: none;
}

.custom-tooltip > * {
    padding: 0;
    margin: 0;
    outline: "none";
}

g.recharts-layer.recharts-pie {
    outline: none;
}

span.recharts-legend-item-text {
    margin-right: 1.5vh;
    -webkit-text-fill-color: $texts;
}

.wrapper-pie {
    ul.recharts-default-legend {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

li.recharts-legend-item {
    margin-bottom: 0.8vh;
}

.text-under-pie {
    width: 8vw;
    font-size: 2.2vh;
    margin-bottom: 0;
}
