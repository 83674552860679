@import "./consts.scss";

.archive-events-page {
    .archive-items {
        margin-right: -16px;
        padding-right: 16px;
        height: vh(47);
        overflow: hidden scroll;
        @include scrollBar-thin;

        & > div:not(:first-child) {
            margin-block-start: vh(2);
        }

        & > div:last-child {
            margin-bottom: 100px;
        }
    }

    .no-events-title {
        text-align: center;
        width: 100%;
        font-size: vh(2.8);
        color: $texts;
    }
}
