.resetCodeButton {
    background: none;
    border: none;
    padding: 0;
    margin: 25px 0;
    cursor: pointer;
    font-size: 18px;
    font: normal normal 600 18px/23px Assistant;
    color: #41596B; // or any other color you prefer
    text-decoration: underline;

    &:focus {
        outline: 2px solid blue; // or any other outline style you prefer
        outline-offset: 2px; // adjust as needed
    }
} 