@import "./consts.scss";

.agency-event-flow {
    display: flex;
    flex-direction: column;
    width: inherit;
    overflow: hidden;
    color: $texts;
    padding-right: vh(1);
}

.event-published-name-date {
    align-self: flex-start;
    padding-right: vh(2.5);
    text-align: right;
    font-size: vh(2);

    .event-publisher {
        font-weight: 700;
        font-size: vh(2);
    }
}

.timer-wrapper {
    border-radius: 50%;
    width: vh(7);
    height: vh(7);

    &.timer {
        padding: 0.2vw;

        .timeline-profile-pic-wrapper {
            border: none;
        }
    }

    .timeline-profile-pic-wrapper {
        width: 100%;
        height: 100%;
        border: 2px solid $lighter-gray;
    }
}

.timeline-event-status-pic-wrapper {
    width: vh(7);
    height: vh(7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid $btn-yellow;

    img {
        object-fit: fill;
        width: 65%;
    }
}

.timeline-content {
    margin-right: vh(-1) !important;
}

.organization-name,
.event-status-title {
    font-weight: 700;
    font-size: vh(2.3);
    margin-bottom: vh(0.8);
}

.event-status-title span {
    display: block;
}

.tickets-span {
    font-size: vh(2.3);
    font-weight: 700;
}

.offer-accepted {
    font-size: vh(1.8);
}

.date-format {
    font-size: vh(1.3);
    opacity: 0.6;
}

.tickets {
    font-size: vh(1.7);
    font-weight: 500;
    margin-top: vh(0.5);
}

.tickets-purchased {
    color: $tickets-status-blue;
}

.timeline-item-content {
    font-size: vh(2.5);
    margin: 0;
    max-width: 10vw;
}

.show-more {
    align-self: center;

    .show-more-text {
        text-decoration: underline;
        cursor: pointer;
    }

    .show-more-arrow {
        position: relative;
        top: 0.5vh;

        &.down {
            top: -0.4vh;
        }
    }
}

.disabled {
    opacity: 0.7;
    // color: $lighter-gray;
    filter: grayscale(100%);
}

.timeline,
.timeline-with-curve {
    flex-direction: row !important;
    flex-wrap: wrap;
    height: 28vh;
    overflow: hidden;
    gap: 5vh 0;
}

.timeline-with-curve {
    height: max-content;
}

.timeline-item {
    flex-direction: column !important;
}

.time-line-separator {
    flex-direction: row !important;

    .arrow {
        padding: vh(1.5);
    }
}