@import "../../style/consts.scss";

@keyframes bigger-popup {
    0% {
        height: vh(42);

    }
    100% {
        height: vh(60);
    }
}

.popupRemoteLogin {
    direction: rtl;
    width: 38vw;
    height: vh(42);
    color: $texts;


    .codeInput{
        direction: ltr;
    }

    p{
        display: block;
        margin: 0;
        margin-top: vh(1);
    }

    .popupLabelText{
        margin-bottom: vh(2);
    }

    .remoteLoginDescriptionContainer {
        margin-bottom: vh(2);
        font-size: 1.8vw;
        
        .remoteLoginEntityName {
            font-weight: bold;
        }
    }
}
.extend {
    overflow: hidden;
    animation: bigger-popup 2s forwards;
}


// this is only for overriding the margin of X button
.popupRemoteLogin {
    :global {
        .close-button-container {
            margin: 0 !important;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
}