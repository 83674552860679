@import "./consts.scss";

.generic-price {
    direction: rtl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .number {
        color: $dark-blue;
        font-size: vh(7);
        font-weight: 700;

        span {
            font-size: vh(4);
            margin-right: 3%;
        }
    }

    .description {
        color: $texts;
        font-size: vh(2.5);
        font-weight: 500;
    }
}
