@import "./consts.scss";


.popup-feedbacks-body {
    direction: rtl;
    height: 90%;
    width: 90%;

}

.scrollable-container {

    display: flex;
    width: 100%;
    flex-direction: column;
    height: 70vh;
    overflow-y: scroll;

}

.scrollable-container::-webkit-scrollbar {
    width: 0.0000000001vw;
}

.bubble-feedbacks {
    position: relative;
    display: inline-block;
    padding: 2vw;
    background-color: #f1f1f1;
    color: #000;
    font-size: 1vw;
    min-height: 3vh;
    min-width: 17vw;
    max-width: 40vw;

}


.bubble-feedbacks::before {
    content: "";
    position: absolute;
    top: 2;
    right: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent rgb(241, 238, 238);
}


.logo-feedbacks {
    background-color: $header;
    width: 4.5vw;
    height: 4.5vw;
    margin-left: 2vw;
    margin-top: 1vw;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-right: 2vw;

    img {
        width: 5.3vw;
        height: 7.5vh;
        border-radius: 50%;
    }
}




.bubble-and-logo-feedbacks {
    flex-direction: row;
    display: flex;
    margin-right: 3vw;
    margin-bottom: 2vh;
}


.organization-name {
    font: normal normal bold 1vw/2vw Assistant;
    color: #41596B;
}




.event-details-popup-feedback {
    margin-bottom: 1vw;
    background-color: #eaeced;
    width: 16vw;
    margin-right: 41%;
    font-size: 1.3vw;
    padding: 0.5vw;
    border-radius: 0.7vw;

}

.imgPopupFeedbacks {
    width: 25vw;
    height: 25vh;
}

.imgBox {
    margin-right: 8.5vw;
}