@import "./consts.scss";

.white-box-wrapper {
    background-color: $header;
    box-shadow: 0px 5px 6px $box-shadow-light;
    border-radius: 5px;
    padding: vh(2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    box-sizing: border-box;
    flex-grow: 1;

    & > :not(.bottom-text) {
        margin-right: auto;
        margin-left: auto;
    }

    & .stats-title {
        text-align: right;
        color: $dark-blue;
        font-size: vh(2.5);
        font-weight: 700;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: auto;
        margin-top: -0.2vh;
    }
}
