@import "./consts.scss";

.i-circle {
    height: vh(1.7);
    aspect-ratio: 1;
    font-size: vh(1.6);
    background-color: $texts;
    color: $header;
    position: relative;
    margin: vh(1);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .tooltip {
        opacity: 0;
        transition: opacity 150ms linear;
        position: absolute;
        background-color: $background-color-primary;
        box-shadow: 0px 5px 6px $box-shadow-light;
        border-radius: 5px;
        color: $texts;
        font-size: vh(2);
        transform: translate(-100%, -100%);
        top: 0;
        left: 0;
        padding: vh(0.5);
        min-width: 13vw;
    }

    &:hover {
        .tooltip {
            opacity: 1;
            transition: opacity 100ms linear;
        }
    }
}
