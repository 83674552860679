@import "./consts.scss";

.wrapper-div-reasons-not-relevant {
    display: flex;
    width: 52vw;

    .pie-graph-right {
        margin-left: 3vw;
        align-self: flex-end;
        height: 27vh;
    }

    .horizontal-graph-left {
        display: flex;
        flex-direction: column;
        height: 30vh;
        border-right: 0.5px solid $separator-bar;
        padding-right: 2vw;
    }
}
