@import "./consts.scss";

.bottom-text {
    direction: rtl;
    text-align: left;
    color: $texts;
    font-weight: 500;
    font-size: 2vh;
    cursor: pointer;

    span {
        text-decoration: underline;
        vertical-align: middle;
    }

    img {
        scale: 80%;
        vertical-align: middle;
    }
}
