@import "./consts.scss";

.whole-seatgroup {
    margin-top: 2vh;
    background-color: $header;
    margin-bottom: 2vh;
    width: max-content;
    box-shadow: 0px 3px 16px $box-shadow-light;
    border-radius: 2vh;

    .seat-group-number-container {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;

        .seat-group-number {
            font-size: vh(2.8);
        }
    }

    .number-of-tickets {
        width: 30%;
        color: $dark-blue;
        font-size: vh(2.5);
        text-align: center;
    }

    .used-seats-container {
        color: $texts;
        font-size: vh(2);
        margin-right: 4vw;
        margin-bottom: vh(2);

        .used-seats-input {
            margin-right: 3%;
            width: 12vh;
        }
    }
}
.seat-group-container {
    background-color: $header;
    border-radius: vh(2);
    width: 60vw;
    height: vh(13);
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 600;
    color: $texts;
    font-family: $font;
}

.accessibility-container-big {
    width: 30%;
    display: flex;
    justify-content: center;

    .accessibility-container {
        width: 60%;
        font-size: vh(2.8);
        display: flex;
        align-items: center;
        color: $texts;

        .accessibilty-title {
            text-align: right;
        }

        .seat-group-image {
            margin-right: 10%;
            width: vh(2.2);
        }

        .eye-icon {
            margin-right: 10%;
            height: vh(2.1);
        }
    }
}

//when in the popup

.is-order {
    width: 55vw;

    .seat-group-number-container {
        width: 40%;

        .seat-group-number {
            font-size: vh(2.5);
        }
    }

    .the-checkbox {
        margin-left: 1vw;
    }

    .number-of-tickets {
        width: 20%;
    }

    .accessibility-container-big {
        width: 40%;
    }
}

.purchase-details {
    padding-right: 4vw;
    padding-bottom: vh(2);
    color: $texts;
    font-size: vh(2);
    font-weight: 500;

    .description {
        margin-bottom: vh(2);
    }
}

@media (max-width: $breakpoint) {
    .whole-seatgroup {
        width: 100%;
    }
    .is-order {
        width: 90vw;
    }

    .seat-group-container {
        width: 100%;
        justify-content: unset;
        height: vh(10);

        .seat-group-number-container {
            padding-right: 2vw;
            .seat-group-number {
                font-size: vh(1.9);
            }
        }

        .number-of-tickets {
            margin-right: 1vw;
            font-size: vh(1.6);
        }

        .accessibility-container-big {
            .accessibility-container {
                font-size: vh(1.9);
                .seat-group-image {
                    width: vh(1.9);
                }
            }
        }
    }
}
