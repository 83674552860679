@import "./consts.scss";

.most-involved-user-container {
    display: flex;
    flex-direction: column;
    width: 25vw;
    height: 55vh;
    gap: 20px;
    color: $texts;

    p {
        margin: 0;
    }

    .image-text-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3vh;

        .gave-most-ticket-description {
            width: 62%;
            text-align: right;
            font-size: 2.5vh;

            .gave-most-ticket-text {
                font-weight: 600;
                margin-bottom: 5%;
                white-space: pre-line;
                width: 120%;
            }

            .gave-most-ticket-selected-user {
                font-weight: 700;
            }
        }

        .gave-most-ticket-logo {
            width: 9vh;
            height: 9vh;
            box-shadow: 0px 2px 6px #00000069;
        }
    }
}
