@import "./consts.scss";

.feedback-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .hearts-container {
        direction: ltr;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(vh(3.5), 2fr));
        grid-gap: vh(1);
        grid-row-gap: vh(1.8);
        overflow: hidden;
        max-height: vh(4 * (3 + 1.8)); // 4 rows times height of heart row gap margin

        img {
            height: vh(3);
            margin: auto;
        }
    }

    .description {
        align-self: flex-start;
        text-align: right;
        width: 100%;

        .count {
            color: $dark-blue;
            font-size: vh(7);
            font-weight: 700;
        }

        .text {
            color: $texts;
            font-size: vh(2.5);
        }
    }
}
