@import "./consts.scss";

$arrow-color: #e4f1f0;
$arrow-height: 9vh;

.arrow-container {
    height: $arrow-height;
    text-align: center;

    .main-part {
        width: 14vw;
        height: 100%;
        background-color: $arrow-color;
        display: inline-block;
        position: relative;

        &::before,
        &::after {
            content: "";
            position: absolute;
            right: 0;
            width: 7vw;
            height: 100%;
            background-color: inherit;
            transform: skewX(53deg);
        }

        &::after {
            transform: skewX(-53deg);
        }

        .text {
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
            font-size: vh(3);
            color: $texts;
            font-weight: 500;
        }
    }

    .triangle {
        display: inline-block;
        width: 0;
        height: 0;
        border-top: calc(#{$arrow-height} / 2) solid transparent;
        border-bottom: calc(#{$arrow-height} / 2) solid transparent;
        border-right: calc(#{$arrow-height} / 1.5) solid $arrow-color;
    }
}
