@import "./consts.scss";

.canceled-events-details-popup {
    direction: rtl;
    width: 90vw;
    height: 90vh;

    .the-title {
        margin-top: vh(-5);
        font-size: vh(3);
    }

    .details-container {
        display: flex;
        gap: 5%;
        padding: vh(1) 0;
        width: 80%;
        border-top: 0.2px solid $gray-separator;
        overflow-y: scroll;
        max-height: vh(78);

        .right-side {
            flex: 1;
            height: fit-content;
            margin-bottom: vh(4);

            .canceled-events-description {
                margin-top: vh(2);
            }
        }

        .seperator {
            width: 0.4px;
            height: vh(75);
            background-color: $gray-separator;
            position: absolute;
            right: 50%;
            margin-top: vh(-1);
        }

        .agencies-canceled-events-container {
            flex: 1;
            height: fit-content;
            margin-bottom: vh(4);

            .text-number-row {
                font-size: vh(2.8);
            }
        }

        &::-webkit-scrollbar {
            width: 0vw;
        }
    }
}
