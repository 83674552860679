@import "./consts.scss";

.event-categories-popup {
    direction: rtl;
    width: 90vw;
    height: 90vh;
    text-align: right;

    .the-title {
        margin-top: vh(-5);
        font-size: vh(3);
    }

    .categories-container {
        width: 80%;
        border-top: 0.2px solid $gray-separator;
        margin: 0 auto;
        overflow-y: scroll;
        max-height: vh(80);
        direction: ltr;
        padding-bottom: vh(10);
        box-sizing: border-box;

        .text-number-row {
            direction: rtl;
            margin-bottom: vh(1.5);
        }

        @include popup-scroll;
    }
}
