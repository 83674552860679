@import "./consts.scss";

.info-icon {
    width: vh(2.5);
    height: vh(2.5);
}

.info-mobility {
    margin-right: 0.5vw;
    margin-top: 0.5vh;
}

.generic-tags-container {
    margin-right: 10.833vw;
    direction: rtl;
    font-family: $font;

    .duration-category {
        font-size: vh(2.5);
    }
}

@mixin flex() {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.seating-tag-div {
    color: $texts;
    font-weight: normal normal 600;
    font-family: Assistant;
    margin-right: 0;
}

.demographic-tag-div {
    @extend .seating-tag-div;

    .info-icon {
        margin-right: vh(0.8);
        width: vh(2.5);
        height: vh(2.5);
    }
}

.tag-categories {
    @include flex();
    align-items: center;
    margin-bottom: vh(1.5);
}
.tag-vip-category {
    margin-bottom: vh(5);
    font-weight: 700;
}
.tag-vip-title {
    font-weight: 700;
    color: $texts;
}

.baseline {
    align-items: baseline;
}

.icon-title-div {
    width: 13vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.special-icon-div {
    flex-direction: row;
}

.tag-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: vh(5);
    flex-wrap: wrap;
    width: 60vw;
}

.a-tag-div {
    display: flex;
    flex-direction: column;
    margin-right: vh(5);
}

.demographic-tag-div {
    flex-direction: row;
}

.small-icons {
    width: 2vw;
    height: auto;
    margin-left: 1.51vw;
}

.small-icons-separate {
    width: 1.5vw;
    height: auto;
    margin-left: 1.51vw;
}

.category-title {
    text-align: right;
    letter-spacing: 0.68px;
    color: $texts;
    font-weight: 600;
}

.category-tags {
    text-align: right;
    letter-spacing: 0.68px;
    color: $texts;
    opacity: 1;
    background-color: rgba(180, 255, 255, 0.42);
    border-radius: vh(1);
    margin-left: 1vw;
    padding-left: vh(1);
    padding-right: vh(1);
    margin-bottom: vh(1.5);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.accessibility-tags {
    margin-left: vh(5);
}

.accessibility-count {
    margin-right: auto;
    width: max-content;
}

.an-access-type {
    color: $texts;
    letter-spacing: 0.68px;
    display: flex;
    align-items: center;
    margin-bottom: vh(1.4);
}

.read-more {
    letter-spacing: 0.68px;
    color: $dark-blue;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
    margin-right: 1vw;
    width: fit-content;
}

@media screen and (max-width: $breakpoint) {
    .icon-title-div {
        width: fit-content;
    }

    // swich to column view
    .tag-accessibility {
        flex-direction: column;

        .a-tag-div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: vh(8.5);
            margin-bottom: vh(2);
        }
    }

    // move the icons to the right
    .tag-categories {
        display: block;
    }

    // change logos size
    .small-icons {
        width: vh(4.2);
        margin-left: vh(4.2);
    }

    // small icons of accessibility fixes
    .small-icons-separate {
        width: vh(2);
        margin-left: vh(1.2);
    }

    // change category title size and add wrapper
    .tag-div {
        margin-right: vh(9.5);
        width: vh(30);
        flex-wrap: wrap;
    }

    .phone-categories {
        margin-right: vh(8);
    }

    // add categorys colums sapces
    .category-tags {
        margin-bottom: vh(1.3);
    }

    // postion fix for accessibility tags
    .a-tag-div {
        margin-right: vh(7.5);
    }

    .a-tag-div.demographic-tag-div {
        align-items: center;
    }

    .a-tag-div.duration-tag-div {
        margin: vh(0.4);
        align-items: center;
    }

    .demographic-category {
        flex-direction: row;
        align-items: center;
    }

    .seating-category {
        flex-direction: row;
        align-items: center;
    }

    .duration-category {
        flex-direction: row;
        align-items: center;
    }

    .seating-tag-div {
        margin-right: vh(8);
    }
}
