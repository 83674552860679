@import "./consts.scss";

.feedback-page {
    .go-back {
        display: flex;
        align-items: center;
        text-align: -webkit-right;
        font: normal normal normal 1.853vh/2.409vh Assistant;
        color: $texts;
        direction: rtl;
        .all-go-back {
            display: flex;
            align-items: center;
            .arrow-img-go-back {
                margin-right: 6vw;
                .img-go-back {
                    width: vh(2);
                }
            }
            .go-back-text {
                font-size: 1.2vw;
                font-weight: 600;
            }
        }
    }
    .feedback-items {
        height: vh(68);
        overflow: hidden auto;
        .container-title {
            margin: vh(3) vh(20) vh(3) 0;
            height: vh(9.6385);
            text-align: right;

            .date {
                text-align: right;
                font: normal normal normal Assistant;
                letter-spacing: 0px;
                color: $texts;
                font-size: vh(2.8);
                font-weight: 400;
            }
            .artist {
                @extend .date;
                font-weight: 600;
                font-size: vh(3.8);
                margin-bottom: vh(1);
            }
        }

        .triangle-skeleton {
            position: relative;
            right: 6.37%;
            top: 5vw;
            transform: rotate(45deg);
            background: linear-gradient(
                to top right,
                rgba(0, 0, 0, 0) 64%,
                rgba(0, 0, 0, 0.11) 0.1%,
                rgba(0, 0, 0, 0.11)
            );
        }
        .container-content-right {
            margin-right: 10vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .container-content {
            margin-top: vh(8.34);
            height: vh(100);
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: vh(2);

            .img-in-bubble {
                align-self: center;
                max-height: vh(20);
                max-width: 70vw;
            }

            .last-img-in-bubble {
                align-self: center;
                max-height: vh(20);
                max-width: 70vw;
            }
        }
        .loading-chat-row {
            display: flex;
            margin-right: vh(15);
            margin-top: vh(2);
        }
    }

    .feedback-items::-webkit-scrollbar {
        width: 0.2vw;
    }
    .feedback-items::-webkit-scrollbar-track {
        width: 0.2vw;
    }

    .feedback-items::-webkit-scrollbar-thumb {
        background-color: $scroll-color;
    }
}
