@import "./consts.scss";

.search-container {
    display: flex;
    align-items: center;
    margin-bottom: vh(2.5);
    position: relative;
    margin-right: 6.5vw;

    &.archive-list {
        margin-right: 0vw;
    }

    label {
        display: flex;
        position: relative;
        left: vh(5);

        .search-icon {
            width: vh(2.5);
        }
    }
}

.no-results {
    font-size: vh(3);
    padding-top: 20vh;
}
