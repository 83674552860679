@import "./consts.scss";

.status {
    margin: auto;
    border: 0.5px solid;
    border-radius: 100px;
    padding: 0.5vh 0.5vw;
    width: 4vw;
    font-weight: 600;
}

.active {
    color: $dark-blue
}

.suspended {
    color: #FF5A5A
}