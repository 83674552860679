@import "./consts.scss";

.arrow-separated-values-container {
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center;

    .value-container {
        flex: 1;
        text-align: center;

        .num-value {
            color: $dark-blue;
            font-size: vh(7);
            font-weight: 700;
        }

        .value-title {
            color: $texts;
            font-size: vh(3);
            font-weight: 500;
            margin: 0 auto;
            max-width: vh(10);
        }
    }

    img {
        transform: scale(-0.7);
    }
}