@import "./consts.scss";

.average-offered-organizations {
    text-align: center;

    .number {
        color: $dark-blue;
        font-size: vh(6);
        font-weight: 700;
    }

    .description {
        color: $texts;
        font-size: vh(2);
        font-weight: 500;
        margin-top: vh(2.8);
    }

    &:hover {
        .number {
            color: #ff5a5a;
        }
    }
}
