@import "./consts.scss";

.generic-input-container {
    margin-top: vh(3);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: vh(-1);
    font-weight: 600;
    font-size: vh(2.3);

    .generic-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: vh(18);
        width: 100%;

        .generic-logo-title {
            margin-bottom: vh(0.5);
            font-size: vh(2.5);
        }

        .generic-first {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            .upload-arrow-image-back {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $inputs-background-color;
                border: 1px solid $inputs-border;
                border-radius: 50%;
                width: 100%;
                aspect-ratio: 1;
                overflow: hidden;
                margin-bottom: vh(1);
                position: relative;

                .upload-arrow-image {
                    height: vh(4);
                }

                .upload-arrow-images-second {
                    .selected-image {
                        object-fit: cover;
                        opacity: 1;
                        position: absolute;
                    }
                }
            }
        }
    }

    .edit-delete-box {
        display: flex;
        gap: vh(3);
        margin-bottom: 3vh;

        .edit-photo-cordinates,
        .delete-photo {
            &:hover {
                cursor: pointer;
            }
            display: flex;
            align-items: center;
            width: fit-content;
            border: 1px solid $box-shadow-light;
            box-shadow: 0px 3px 6px $box-shadow;
            border-radius: 50%;
            padding: vh(1);

            .edit-icon,
            .delete-icon {
                width: vh(2.5);
            }
        }
    }

    .link-error {
        margin: 1vh 0 vh(2);
        color: $error;
        font-weight: 400;
        font-size: vh(1.7);
        height: vh(1);

        &.limit {
            color: $texts;
            font-size: vh(1.5);
        }
    }
}
