@import "./consts.scss";

.error-screen {
    z-index: 9999;
    position: fixed;
    bottom: vh(5);
    width: 100vw;
    display: flex;
    justify-content: center;
}
.error-container {
    background-color: $error;
    width: fit-content;
    padding-left: 2%;
    padding-right: 2%;
    height: 3vw;
    min-height: 50px;
    color: white;
    display: flex;
    align-items: center;
    border-radius: vh(1);
    gap: vh(1);

    .close-button-error {
        cursor: pointer;
    }
    .error-text {
        text-align: end;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: larger;
        font-weight: 600;
    }
}

@media screen and (max-width: $breakpoint) {
    .error-container {
        min-height: vh(5);
        height: 10vw;
        font-size: vh(2.2);
    }
}
