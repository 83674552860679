@import "./consts.scss";

.live-box {
    display: flex;
    flex-direction: row;
    width: 100%;

    .live-box-event-details {
        cursor: pointer;
        display: flex;
        align-items: center;
        text-align: right;
        width: 17%;
        height: fit-content;
        flex-direction: column;
        color: $texts;
        font-family: "Assistant";

        .live-box-img {
            height: vh(12);
            width: 90%;
            overflow: hidden;

            .live-event-cover {
                left: -50%;
                translate: 50%;
            }
        }

        .live-box-text {
            width: 90%;
            color: $header-logo;
            padding-bottom: vh(1.5);

            .live-box-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: vh(2.5);
            }

            .live-box-date {
                font-size: vh(2);
            }
        }

        .live-box-time-left {
            width: 90%;
            font-size: vh(2);
            padding-bottom: vh(1.5);
        }

        .live-box-tickets {
            width: 90%;
            font-size: vh(1.7);
        }

        .bold {
            font-weight: 700;
        }
    }

    .live-box-event-map {
        width: 83.6%;
        display: flex;
        border-right: $scroll-color 0.1vw solid;
    }

    .live-box-tickets-details-btn {
        color: $texts;
        background-color: transparent;
        border-radius: vh(10);
        padding: vh(0.8);
        margin-top: vh(1);
        width: 90%;
        outline: 1px solid $texts;
        box-shadow: none;
        font-size: vh(1.9);
    }
}
