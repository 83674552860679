@import "./consts.scss";

.header {
    position: sticky;
    top: 0;
    z-index: 5;
    height: $header-height;
    width: 100vw;
    background-color: #0c414f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding-right: 2.5vw;
    padding-left: 3.5vw;
    box-sizing: border-box;

    .navbar {
        display: flex;
        color: white;
        gap: 5vw;
    }

    .link {
        text-decoration: none;
        color: white;
        font-size: vh(2.5);
        font-weight: 500;
    }

    .active {
        font-weight: 700;
    }

    .profile-dropdown {
        left: 3.5vw;

        .element-text {
            color: #323232;
        }
    }

    .enter-logo {
        height: 75%;
    }

    .img-container {
        cursor: pointer;
        height: 50%;

        img {
            // aspect-ratio: 1;
            height: 100%;
        }
    }
}
