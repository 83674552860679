@import "./consts.scss";

.title-of-Waiting-Request {
    margin-right: 5vw;
    margin-left: 5vw;
    margin-top: 2vh;
    text-align: center;
    font: normal normal bold 4.5vh / vh(4.8) Assistant;
    letter-spacing: 1.08px;
    color: $texts;
    margin-bottom: 2.3vw;
}

.the-popup,
.popup-with-img {
    background-color: $background-color-primary;
    border-color: black;
    border-radius: vh(3.148);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 62.39vw;
    height: vh(59.44);
    text-align: center;

    &.full-screen {
        width: 80vw;
        height: 80vh;
    }

    // any content that is printed on the popup
    .flex-center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .close-button-container {
        //just the x button and its needs
        margin-top: vh(1.5);
        margin-right: 2.5vw;

        .close-button-text {
            color: $texts;
            font-size: vh(6);
        }
    }

    // currently my fix is working therefor might remove all additional icon css.
    .the-icon {
        display: block;
        width: vh(10.3);
        object-fit: contain;
        margin-bottom: vh(2.1);
    }

    // this serves as scss for all titles
    .the-title {
        text-align: center;
        font: normal normal bold vh(3.7) / vh(4.8) Assistant;
        letter-spacing: 1.08px;
        color: $texts;
        margin-bottom: vh(2.59);
    }

    // if popup was sent children
    .the-text {
        text-align: center;
        font: normal normal normal vh(2.7) / vh(2.5) Assistant;
        letter-spacing: 0.81px;
        color: $texts;
        text-transform: uppercase;

        // background-color: aqua;
        height: vh(6);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    // this is where the close button sits
    .the-header {
        display: flex;
    }
}

.popup-with-img {
    background-color: $background-color-primary;
    border-color: black;
    border-radius: vh(1.2);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 55vw;
    height: vh(75);
    display: flex;
    flex-direction: column;

    .flex-center {
        height: vh(65);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .close-button-text {
            color: $texts;
            font-size: vh(6);
        }

        .photo-popup {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 55vw;
            height: vh(75);

            .the-img {
                margin-bottom: vh(1);
                max-height: vh(64);
                max-width: 40vw;
            }
        }
    }
}

@media (max-width: 770px) {
    .the-popup {
        width: 90%;
    }

    .close-button {
        font-size: vh(5);

        .close-button-text {
            margin-bottom: 1.5vh;
            font-size: vh(4.5);
        }
    }

    .popup-with-img {
        width: 85vw;
        height: vh(80);
        justify-content: center;

        .flex-center {
            height: vh(70);
            width: 100%;
            justify-content: center;
            align-items: center;

            .photo-popup {
                width: 100%;

                .the-img {
                    width: 75vw;
                    max-width: none;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .the-button {
        .yellow {
            font-size: vh(2);
        }
    }
}

.popup-waiting-accept {
    width: 40vw;
    height: 56vh;
    direction: rtl;
}

.imageIconWaitingStyle {
    height: 6vh;
}
.titleImageIconWaitingStyle {
    font: normal normal normal 3vh / vh(0) Assistant;
    margin-bottom: 2.5vw;
}
