@import "./consts.scss";

.tickets-utilization-wrapper {
    display: flex;
    align-items: center;
    width: 40vw;
    justify-content: space-evenly;
    height: 25vh;

    .border-in-middle {
        border-left: 0.5px solid $separator-bar;
        height: 10vw;
    }
}
