@import "./consts.scss";

.average-time-from-offer {
    text-align: center;

    .time {
        display: flex;
        justify-content: center;
        align-items: baseline;
        gap: 4%;
        font-size: vh(6);
        color: $dark-blue;
        font-weight: 700;

        .time-unit {
            .text {
                font-size: vh(2.5);
                font-weight: 500;
                margin-top: vh(-1);
            }
        }
    }

    .description {
        color: $texts;
        font-size: vh(2);
        font-weight: 500;
        margin-top: vh(1);
    }

    &:hover {
        .time {
            color: $light-blue;
        }
    }
}